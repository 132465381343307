import { Injectable } from '@angular/core';
import { SnotifyService, SnotifyPosition, SnotifyToastConfig } from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class TosterConfigService {
  style = 'material';
  title = '';
  body = '';
  timeout = 3000;
  position: SnotifyPosition = SnotifyPosition.rightBottom;
  progressBar = true;
  closeClick = true;
  newTop = true;
  backdrop = -1;
  dockMax = 1;
  blockMax = 1;
  pauseHover = true;
  titleMaxLength = 15;
  bodyMaxLength = 80;

  constructor(private snotifyService: SnotifyService) { }

  /*
  Change global configuration
   */
  getConfig(): SnotifyToastConfig {
      this.snotifyService.setDefaults({
          global: {
              newOnTop: this.newTop,
              maxAtPosition: this.blockMax,
              maxOnScreen: this.dockMax,
          }
      });
      return {
          bodyMaxLength: this.bodyMaxLength,
          titleMaxLength: this.titleMaxLength,
          backdrop: this.backdrop,
          position: this.position,
          timeout: this.timeout,
          showProgressBar: this.progressBar,
          closeOnClick: this.closeClick,
          pauseOnHover: this.pauseHover
      };
  }
}
