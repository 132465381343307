import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AuthenticationRoutingModule
    ],
    declarations: [
      LoginComponent
    ]
})
export class AuthenticationModule { }
