import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  unreadChatMessages: any = '';
  runningListCount: any;
  distributorId: any;
  customerId:number;
  currencySymbol: any = 'Rs';
  productCache = true;
  name:string='';
  customerImage:string;
}
