import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";

import { SnotifyModule, SnotifyService, ToastDefaults } from "ng-snotify";

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { DragulaService } from "ng2-dragula";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { AuthenticationModule } from "./authentication/authentication.module";
import { RoleGuardService } from "./shared/auth/role-guard.service";
import { Globals } from "./globals";

// import { ShowHidePasswordModule } from "ngx-show-hide-password";

import { ModalComponent } from "./directives/modal.component";
import { ModalService } from "app/services/common/modal.service";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    ModalComponent
    // LoginComponent
  ],
  imports: [
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    AuthenticationModule,
    SnotifyModule.forRoot(),
    HttpClientModule,
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    // ShowHidePasswordModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo"
    })
  ],
  providers: [
    { provide: "SnotifyToastConfig", useValue: ToastDefaults },
    SnotifyService,
    AuthService,
    AuthGuard,
    RoleGuardService,
    DragulaService,
    Globals,
    ModalService
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}
