import { RouteInfo } from "./sidebar.metadata";

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: "/dashboard/analytics",
        title: "Dashboard",
        icon: "icon-home",
        class: "",
        badge: "",
        badgeClass: "badge badge-pill badge-danger mt-1",
        isExternalLink: false,
        submenu: []
    },
    //     {
    //       path: '/banner/list', title: 'Banners', icon: 'icon-picture', class: '', badge: '', badgeClass: '', isExternalLink: false,
    //       submenu: []
    //   },
    {
        path: "/chapter/list",
        title: "Chapters",
        icon: "icon-picture",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: []
    },
    // {
    //   path: '/paragraph/list', title: 'Paragraph', icon: 'icon-bar-chart', class: '', badge: '', badgeClass: '', isExternalLink: false,
    //   submenu: []
    // },
    {
        // tslint:disable-next-line:max-line-length
        path: "/customers/list",
        title: "Users",
        icon: "icon-user",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: []
    },
    {
        path: "/contactus/list",
        title: "Messages",
        icon: "icon-user",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: []
    },
    {
        path: "/aboutus/add",
        title: "About us",
        icon: "icon-bar-chart",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: []
    },
    {
        // tslint:disable-next-line:max-line-length
        path: "/payments/list",
        title: "Payments",
        icon: "icon-bar-chart",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: []
    },
    {
        path: "/admin/edit",
        title: "Admin",
        icon: "icon-user",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: []
  },
  {
    path: "/notification/push",
    title: "Notification",
    icon: "icon-bar-chart",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: []
  }
];
