import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
// import { decode } from 'jwt-decode';
import { JwtHelper } from 'angular2-jwt';
const claims = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/';

@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    const expectedRole = route.data.expectedRole;
    const token = localStorage.getItem('TokenInfo');

    const jwtHelper: JwtHelper = new JwtHelper();
    // const tokenPayload = decode(token);
    const tokenPayload = jwtHelper.decodeToken(token);
    const role = tokenPayload[claims + 'role'];

    if (!this.auth.isAuthenticated() || role !== expectedRole) {
     // this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
