import { Router, ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { JwtHelper } from "angular2-jwt";
import { environment } from "../../../environments/environment.prod";
const claims = "http://schemas.microsoft.com/ws/2008/06/identity/claims/";
import { Globals } from "app/globals";

const sid = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid";
const givenname =
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname";
const actor = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/actor";

@Injectable()
export class AuthService {
  token: string;
  isAdministrator = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private globals: Globals
  ) {}

  signupUser(userName: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(credentials: any) {
    return this.http.post<any>(environment.apiUrl + "login", credentials).pipe(
      map(user => {
        if (user && user.token) {
          localStorage.setItem("TokenInfo", JSON.stringify(user));

          const token = localStorage.getItem("TokenInfo");
          const jwtHelper: JwtHelper = new JwtHelper();
          const tokenPayload = jwtHelper.decodeToken(token);
          // console.log(tokenPayload);
          const role = tokenPayload[claims + "role"];
          this.globals.distributorId = tokenPayload[sid];
          this.globals.customerId = tokenPayload[sid];
          this.globals.name = tokenPayload[givenname];
          this.globals.customerImage = tokenPayload[actor];
        }
        return user;
      })
    );
  }

  logout() {
    localStorage.removeItem("TokenInfo");
    this.router.navigate(["login"]);
  }

  getToken() {
    this.token = localStorage.getItem("TokenInfo");
    if (this.token) {
      return this.token;
    } else {
      return null;
    }
  }

  isAuthenticated() {
    this.token = localStorage.getItem("TokenInfo");
    const jwtHelper: JwtHelper = new JwtHelper();
    if (this.token && !jwtHelper.isTokenExpired(this.token)) {
      return true;
    }
    this.router.navigate(["login"]);
    return false;
  }

  isAdmin() {
    const token = localStorage.getItem("TokenInfo");

    const jwtHelper: JwtHelper = new JwtHelper();
    // const tokenPayload = decode(token);
    const tokenPayload = jwtHelper.decodeToken(token);
    const role = tokenPayload[claims + "role"];
    this.globals.distributorId = tokenPayload[sid];
    this.globals.customerId = tokenPayload[sid];
    // this.globals.name = tokenPayload[givenname];
    // this.globals.customerImage = tokenPayload[actor];

    if (!this.isAuthenticated() || role !== "Admin") {
      // this.router.navigate(['login']);
      return false;
    }
    this.isAdministrator = true;
    return true;
  }

  getDistributorId() {
    const token = localStorage.getItem("TokenInfo");
    const jwtHelper: JwtHelper = new JwtHelper();
    const tokenPayload = jwtHelper.decodeToken(token);
    const role = tokenPayload[claims + "role"];
    return tokenPayload[sid];
  }
}
