export const environment = {
  production: false,
  //apiUrl: "https://localhost:44334/api/"
  //apiUrl: "http://localhost/api/"

  //New Dev URL
  apiUrl: "https://tcqapp.theclearquran.org/api/"

  //New Prod URL
  //apiUrl: "https://appv2.theclearquran.org/api/"
};
