import { Routes, RouterModule } from "@angular/router";

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "dashboard",
    loadChildren: "app/dashboard/dashboard.module#DashboardModule"
  },
  {
    path: "customers",
    loadChildren: "app/customers/customer.module#CustomerModule"
  },
  {
    path: "aboutus",
    loadChildren: "app/aboutus/aboutus.module#AboutUsModule"
  },
  {
    path: "chapter",
    loadChildren: "app/chapter/chapter.module#ChapterModule"
  },
  {
    path: "paragraph",
    loadChildren: "app/paragraph/paragraph.module#ParagraphModule"
  },
  {
    path: "contactus",
    loadChildren: "app/contactus/contactus.module#ContactUsModule"
  },
  {
    path: "payments",
    loadChildren: "app/payments/payments.module#PaymentsModule"
  },
  {
    path: "admin",
    loadChildren: "app/admin/admin.module#AdminModule"
  },
  {
    path: "notification",
    loadChildren: "app/notification/notification.module#NotificationModule"
  }
];
