import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { environment } from '../../../environments/environment.prod';
import { AuthService } from '../../shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  token = this._authService.getToken();
  parsed_token = JSON.parse(this.token);

  constructor(private http: HttpClient, private _authService: AuthService) { }

  GetSettings<T>(): Observable<T> {
    const endpointUrl = environment.apiUrl + 'Settings/Get';
    return this.http.get<T>(endpointUrl, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.parsed_token.token,
          'Content-Type': 'application/json'
      })
    });
  }
  UpdateOrderStatus<T>(data: any): Observable<T> {
    const endpointUrl = environment.apiUrl + 'Settings/Update';
    return this.http.post<T>(endpointUrl, data , {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
    })
  });
  }
  AddSettings<T>(data: any): Observable<T> {
    const endpointUrl = environment.apiUrl + 'Settings/Add';
    return this.http.post<T>(endpointUrl, data , {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
    })
  });
  }
}
