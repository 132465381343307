import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { Globals } from 'app/globals'; 
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  currentLang = 'en';
  toggleClass = 'ft-maximize';
  profilePic: any;
  public isCollapsed = true;
  constructor(public translate: TranslateService, private _authService: AuthService
              , private globals: Globals) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize'
    }
  }
  ngOnInit() {

    this.profilePic = this.globals.customerImage;

    //this.getUser();
}
//  getUser() {
//    const id = this._authService.getDistributorId();
// this._distributorService.GetDistributor(id).subscribe((data: any) => {
// this.profilePic = data.image;
//},
//err => {});
//  }

  LogOut() {
    this._authService.logout();
  }
}
