import { Component, OnInit } from "@angular/core";
import { ROUTES } from "./sidebar-routes.config";
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RoleGuardService } from "../auth/role-guard.service";
import { AuthService } from "../auth/auth.service";
import { Globals } from "../../globals";

declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html"
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private _globals: Globals
  ) {}

  ngOnInit() {
    $.getScript("./assets/js/app-sidebar.js");
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    setInterval(() => {
      //   this.menuItems[6].badge = this._globals.unreadChatMessages;
      //   this.menuItems[3].badge = this._globals.runningListCount;
      this.showMenu();
    }, 50);
  }

  showMenu() {
    if (this.authService.isAdmin()) {
      //    $('#Distributor').css('display', '');
      // $('#Dashboard').attr('hidden', true); // css('display', 'none');
      return true;
    }
    $("#Distributor").css("display", "none");
    return false;
  }
}
