import { Component, ViewContainerRef } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { environment } from 'environments/environment.prod';
import { SnotifyService, SnotifyPosition, SnotifyToastConfig } from 'ng-snotify';
import { TosterConfigService } from 'app/services/snotify/toster-config.service';
import { Router } from '@angular/router';
import { Globals } from './globals';
import { SettingsService } from './services/settings/settings.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
  pharmacies: any[];
  chatMessage: any[];
  activePharmacyId: string;
  activeChatId: string;
  activeChatUser: string;
  activeChatUserImg: string;
  hubConnection: HubConnection;

  constructor(private snotifyService: SnotifyService,
    private _tosterConfigService: TosterConfigService,
    private router: Router, private globals: Globals,
    private _settingsService: SettingsService) {
}
}
