import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/auth/auth.service';
import { SnotifyService, SnotifyPosition, SnotifyToastConfig } from 'ng-snotify';
import { TosterConfigService } from 'app/services/snotify/toster-config.service';
import { SettingsService } from '../../services/settings/settings.service';
import { Globals } from '../../globals';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [AuthService]
})

export class LoginComponent {

    @ViewChild('f') loginForm: NgForm;
    invalidLogin: any;
    constructor(private router: Router, private _authService: AuthService,
        private route: ActivatedRoute, private snotifyService: SnotifyService,
      private _tosterConfigService: TosterConfigService,
      private globals: Globals,
      private _settingsService: SettingsService) { }

    onSubmit() {

      //console.log(JSON.stringify(this.loginForm.value));
        // this.loginForm.reset();
         // let credentials = JSON.stringify(this.loginForm.value);
         const credentials = {
          EmailId : this.loginForm.value.EmailId,
            Password : this.loginForm.value.Password
          }
          this._authService.signinUser(credentials).subscribe(response => {
            this.invalidLogin = false;
          
            this.snotifyService.success('successful', 'Login', this._tosterConfigService.getConfig());
            this.router.navigate(['/']);
          }, err => {
            this.invalidLogin = true;
            this.snotifyService.clear();
            this.snotifyService.error('Please Enter correct credentials', 'Invalid Credentials', this._tosterConfigService.getConfig());
          });
    }
    // On Forgot password link click
    onForgotPassword() {
        // this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
    }
    // On registration link click
    onRegister() {
        // this.router.navigate(['register'], { relativeTo: this.route.parent });
  }
}
